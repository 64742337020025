<template>
  <layout-horizontal>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-horizontal-layout-brand />
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    LayoutHorizontal,
    // AppCustomizer,
    Navbar,
    AppNavbarHorizontalLayoutBrand,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
<style>
[dir="rtl"] .navbar-container .bookmark-wrapper{
  right: 180px;
  position: absolute;
}
.navbar-header{
  left: calc(5% - 15px) !important;
}
.header-navbar{
  background: #3999b7 !important;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.5) !important;
  border-bottom: 1px solid #1a6780;
}

.dropdown-item span{
  color: #555 !important;
}
.header-navbar .navbar-container ul.navbar-nav li > a.nav-link,.header-navbar .navbar-container .nav-link svg,.header-navbar .navbar-container span.locale-link{
  color: #fff!important;
}
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-text{
  color: #fff!important;
}
</style>
